<template>
  <div class="row">
    <div class="col-md-10">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{ role.name }}</h4>
          <p>{{ role.description }}</p>
          <h6 class="card-title mt-5 mb-4">Permissions</h6>
          <div class="row">
            <div class="col-md-4 mb-3" v-for="(permission, i) in role.permissions" :key="`permission-${i}`">
              {{ permission.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <router-link :to="{ name: 'roles.edit' }" class="btn btn-sm btn-block btn-warning"><i class="lni-pencil mr-2"></i>Edit</router-link>
      <a href="#" @click.prevent="destroy" class="btn btn-danger btn-block btn-sm"><i class="lni-trash mr-2"></i>Delete</a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      role: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start()

      this.$axios.get(`/api/v1/dashboard/roles/${this.$route.params.id}`).then(response => {
        this.role = response.data.role

        this.$loader.stop()
      })
    },

    destroy() {
      this.$loader.start()

      this.$axios.delete(`/api/v1/dashboard/roles/${this.$route.params.id}`).then(() => {
        this.$loader.stop()
        
        this.$router.push({ name: 'roles.index' })
      })
    }
  }
}
</script>